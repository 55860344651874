import {
  MemberAddContainer,
  MemberDetail,
  MemberEditContainer,
  MemberListContainer,
} from './HouseMember'
import React, { useState } from 'react'

import { HouseAddContainer } from './House/HouseAdd'
import { HouseEditContainer } from './House/HouseEdit'
import { HouseListContainer } from './House/HouseList'
import { HouseMap } from './HouseMap'
import { Modal } from 'antd'
import { houseStore } from 'Models/HouseModel'
import { memberStore } from 'Models/HouseMemberModel'
import styled from 'styled-components'

const Container = styled.section`
  height: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: row;
`

const Left = styled.section`
  flex: 0.2;
  min-width: 340px;
  padding: 30px;
  border-right: 1px solid gray;
`

const Right = styled.section`
  flex: 1;
  padding: 30px;
  display: flex;
  flex-direction: row;
`

const MemberListBox = styled.div`
  flex: 0.3;
  min-width: 300px;
`

const MapBox = styled.div`
  margin-left: 30px;
  flex: 1;
`

export const HomePage = (props) => {
  const [shouldShowAddHouseModal, setShouldShowAddHouseModal] = useState(false)
  const [shouldShowAddMemberModal, setShouldShowAddMemberModal] = useState(
    false
  )
  const [loadingMember, setLoadingMember] = useState(false)
  const [shouldShowMemberDetail, setShouldShowMemberDetail] = useState(false)
  const [shouldShowMemberEditModal, setShouldShowMemberEditModal] = useState(
    false
  )
  const [shouldShowHouseEditModal, setShouldShowHouseEditModal] = useState(
    false
  )

  const [lastPinnedLocation, setLastPinnedLocation] = useState({
    lat: 10.6343527660001,
    lng: 103.503359524,
  })

  function onAddHouseButtonPress() {
    setShouldShowAddHouseModal(true)
  }

  function onAddMemberButtonClick() {
    setShouldShowAddMemberModal(true)
  }

  function onMemberSaveFinish() {
    setShouldShowAddMemberModal(false)
  }

  function onEditMemberButtonClick() {
    setShouldShowMemberEditModal(true)
  }

  async function onHouseItemClick(house) {
    memberStore.setSelectedMember(undefined)
    houseStore.setSelectedHouse(house)
    setShouldShowMemberDetail(false)
  }

  function onHouseItemEditClick(house) {
    memberStore.setSelectedMember(undefined)
    houseStore.setSelectedHouse(house)
    setShouldShowHouseEditModal(true)
  }

  function onMemberItemClick() {
    setShouldShowMemberDetail(true)
  }

  function onMapPinned(location) {
    setLastPinnedLocation(location)
  }

  return (
    <Container>
      <Left>
        <HouseListContainer
          onHouseItemClick={onHouseItemClick}
          onAddButtonPress={onAddHouseButtonPress}
          onEditClick={onHouseItemEditClick}
        ></HouseListContainer>
      </Left>
      <Right>
        <MemberListBox>
          <MemberListContainer
            loading={loadingMember}
            onAddButtonClick={onAddMemberButtonClick}
            onMemberItemClick={onMemberItemClick}
          ></MemberListContainer>
        </MemberListBox>
        {shouldShowMemberDetail ? (
          <MemberDetail
            onEditButtonClick={onEditMemberButtonClick}
          ></MemberDetail>
        ) : (
          <MapBox>
            <HouseMap></HouseMap>
          </MapBox>
        )}
      </Right>
      <Modal
        visible={shouldShowAddHouseModal}
        onCancel={() => setShouldShowAddHouseModal(false)}
        footer={[]}
        width={1000}
      >
        <HouseAddContainer
          onSaveFinish={() => setShouldShowAddHouseModal(false)}
          onMapPinned={onMapPinned}
          lastPinnedLocation={lastPinnedLocation}
        ></HouseAddContainer>
      </Modal>
      <Modal
        visible={shouldShowAddMemberModal}
        onCancel={() => setShouldShowAddMemberModal(false)}
        footer={[]}
        width={1000}
      >
        <MemberAddContainer
          onSaveFinish={onMemberSaveFinish}
        ></MemberAddContainer>
      </Modal>

      {shouldShowMemberEditModal && (
        <Modal
          visible={shouldShowMemberEditModal}
          onCancel={() => setShouldShowMemberEditModal(false)}
          footer={[]}
          width={1000}
        >
          <MemberEditContainer
            onSaveFinish={() => setShouldShowMemberEditModal(false)}
          ></MemberEditContainer>
        </Modal>
      )}

      {shouldShowHouseEditModal && (
        <Modal
          visible={shouldShowHouseEditModal}
          onCancel={() => setShouldShowHouseEditModal(false)}
          footer={[]}
          width={1000}
        >
          <HouseEditContainer
            onSaveFinish={() => setShouldShowHouseEditModal(false)}
            lastPinnedLocation={lastPinnedLocation}
          ></HouseEditContainer>
        </Modal>
      )}
    </Container>
  )
}
