import * as api from './api'

import { applySnapshot, flow, getParent, types } from 'mobx-state-tree'

import moment from 'moment'
import { userStore } from 'Models/UserModel'

export const HouseMember = types
  .model('HouseMember', {
    id: types.identifier,
    name: types.maybeNull(types.string),
    nameLatin: types.maybeNull(types.string),
    gender: types.maybeNull(types.string),
    job: types.maybeNull(types.string),
    phoneNo: types.maybeNull(types.string),
    dateOfBirth: types.maybeNull(types.string),
    placeOfBirth: types.maybeNull(types.string),
    idNo: types.maybeNull(types.string),
    memberRelation: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    facebookName: types.maybeNull(types.string),
    dateOfMember: types.maybeNull(types.string),
    memberNo: types.maybeNull(types.string),
    pollingOfficeNo: types.maybeNull(types.string),
    photo: types.maybeNull(types.string),
    village: types.maybeNull(types.string),
    commune: types.maybeNull(types.string),
    district: types.maybeNull(types.string),
    province: types.maybeNull(types.string),
    roleInParty: types.maybeNull(types.string),
    educationLevel: types.maybeNull(types.string),
    hasMemberCard: types.maybeNull(types.boolean),
  })
  .actions((self) => ({
    delete: flow(function* () {
      yield api.deleteById(self.id)
      getParent(self, 2).removeMember(self)
    }),
    edit: flow(function* (values) {
      const data = {
        id: self.id,
        ...values,
        editor: userStore.name,
      }
      yield api.editMember(data)
      applySnapshot(self, data)
    }),
  }))

const MemberStore = types
  .model('MemberStore', {
    members: types.array(HouseMember),
    selectedMember: types.maybe(types.safeReference(HouseMember)),
  })
  .actions((self) => ({
    loadMembers: flow(function* (houseId) {
      const result = yield api.getMemberByHouseId(houseId)
      self.members = result
    }),
    loadMembersByFilter: flow(function* (filter) {
      const result = yield api.getMembersByFilter(filter)
      self.members = result
    }),
    setSelectedMember(member) {
      self.selectedMember = member
    },
    addMember: flow(function* (member) {
      const data = {
        ...member,
        editor: userStore.name,
      }
      const result = yield api.addMember(data)
      self.members.push(result)
      return result
    }),
    removeMember(item) {
      self.members.splice(self.members.indexOf(item), 1)
    },
  }))

export const memberStore = MemberStore.create({})
