import { GoogleMap, useLoadScript } from '@react-google-maps/api'

import React from 'react'

const containerStyle = {
  width: '100%',
  height: '100%',
}

const center = {
  lat: 10.6343527660001,
  lng: 103.503359524,
}

function MyComponent(props) {
  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBprYTG4ct81WZSEHNSV2MNRJhcJ1BMLn4',
  })

  if (!isLoaded) {
    return <></>
  }

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={15}
      {...props}
    >
      {props.children}
      <></>
    </GoogleMap>
  )
}

export const Map = React.memo(MyComponent)
