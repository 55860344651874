// @ts-ignore
import communeFile from './data/communes.yml'
// @ts-ignore
import districtFile from './data/districts.yml'
import moment from 'moment'
// @ts-ignore
import provinceFile from './data/provinces.yml'
// @ts-ignore
import villageFile from './data/villages.yml'
import yaml from 'yamljs'

export interface CsvRecord {
  AgeGroup: string
  Age_1: string
  City: string
  CityCode: string
  Com_Sangkat: string
  CurrentDate: string
  DOB_Edit: string
  DateOfBirth: string
  DateOfParty: string
  EduCode: string
  Edu_Code: string
  Edu_Level: string
  FaRole_Job: string
  FamCode: string
  FamiRole: string
  IDNo_1: string
  MemCard: string
  MemCardCode: string
  MemberCard: string
  OBJECTID: string
  PB_Phumi: string
  POB_ProCode: string
  PaRoleCode: string
  Par_Role: string
  PartyGroup: string
  PartyRole: string
  Phum: string
  PhumiCode: string
  PlaceOfBirth: string
  PollingNo_1: string
  Procode: string
  Province: string
  Sangkat: string
  SangkatCode: string
  Sex: string
  SexE: string
  SexEdit: string
  Sub_PartyBranch: string
  Surname_Name: string
  houseId: string
}

// AgeGroup: "G5"
// Age_1: "47"
// City: "ព្រះសីហនុ"
// CityCode: "1801" // district code
// Com_Sangkat: "1"
// CurrentDate: "3/21/2021 0:00"
// DOB_Edit: "12/7/1973"
// DateOfBirth: "០៧/១២/១៩៧៣"
// DateOfParty: "១៩៩៧"
// EduCode: "ថ្នាក់ទី៧ថ្មី"
// Edu_Code: "45"
// Edu_Level: "៧"
// FaRole_Job: "អនុភូមិ"
// FamCode: "អនុភូមិ"
// FamiRole: "77"
// IDNo_1: "130015069"
// MemCard: "1"
// MemCardCode: "មាន"
// MemberCard: "មាន"
// No: "1"
// No_1: "1"
// OBJECTID: "1"
// PB_Phumi: "4"
// POB_ProCode: "22"
// PaRoleCode: "ប្រធានក្រុមបក្ស"
// Par_Role: "1"
// PartyGroup: "1"
// PartyRole: "ប្រធានក្រុមបក្ស"
// Phum: "3"
// PhumiCode: "18010103"
// PlaceOfBirth: "ស្វាយរៀង"
// PollingNo_1: "13"
// Procode: "18" // province code
// Province: "ព្រះសីហនុ"
// Sangkat: "លេខ១"
// SangkatCode: "180101"
// Sex: "ប"
// SexE: "ប្រុស"
// SexEdit: "1"
// Sub_PartyBranch: "1"
// Surname_Name: "ចុង វ៉ាន់"

export const convertFromCsv = (record: CsvRecord) => {
  const dateOfBirthMoment = moment(record.DOB_Edit, 'DD/M/YYYY')
  const id = `${record.Surname_Name}.${dateOfBirthMoment.format('DD-MM-YYYY')}`
  return {
    id,
    name: record.Surname_Name,
    district: getDistrictNameByCode(record.CityCode, record.City),
    commune: getCommuneNameByCode(record.SangkatCode, record.Sangkat),
    dateOfBirth: dateOfBirthMoment,
    educationLevel: record.EduCode,
    job: record.FaRole_Job,
    idNo: record.IDNo_1,
    memberNo: record.MemCardCode,
    hasMemberCard: record.MemberCard && record.MemberCard === 'មាន',
    village: getVillageNameByCode(record.PhumiCode, record.Phum),
    roleInParty: record.PartyRole,
    placeOfBirth: record.PlaceOfBirth,
    pollingOfficeNo: record.PollingNo_1,
    province: record.Province,
    gender: record.SexE,
    subPartyBranch: record.Sub_PartyBranch,
    houseId: record.houseId,
  }
}

let provinceDoc = undefined
function getProvinceByCode(code: string) {
  if (!provinceDoc) {
    provinceDoc = yaml.load(provinceFile)
  }

  const { provinces } = provinceDoc
  return provinces[code]
}

function getProvinceNameByCode(code: string, fallback: string) {
  const province = getProvinceByCode(code)
  if (province) {
    return province.name.km
  }
  return fallback
}

let districtDoc = undefined
function getDistrictByCode(code: string) {
  if (!districtDoc) {
    districtDoc = yaml.load(districtFile)
    console.log('caching district doc')
  }
  const { districts } = districtDoc
  return districts[code]
}

function getDistrictNameByCode(code: string, fallback: string) {
  const record = getDistrictByCode(code)
  if (record) {
    return record.name.km
  }
  return fallback
}

let communeDoc = undefined
function getCommuneByCode(code: string) {
  if (!communeDoc) {
    communeDoc = yaml.load(communeFile)
    console.log('caching commune doc')
  }
  const { communes } = communeDoc
  return communes[code]
}

function getCommuneNameByCode(code: string, fallback: string) {
  const record = getCommuneByCode(code)
  if (record) {
    return record.name.km
  }
  return fallback
}

let villageDoc = undefined
function getVillageByCode(code: string) {
  if (!villageDoc) {
    villageDoc = yaml.load(villageFile)
    console.log('caching village doc')
  }
  const { villages } = villageDoc
  return villages[code]
}

function getVillageNameByCode(code: string, fallback: string) {
  const record = getVillageByCode(code)
  if (record) {
    return record.name.km
  }
  return fallback
}
