import { CsvRecord, convertFromCsv } from './type'

import Promise from 'bluebird'
import { SERVER_URL } from 'Constants/Server'
import axios from 'axios'
import moment from 'moment'
import { userStore } from 'Models/UserModel'

export const addHouse = async (house) => {
  const url = `${SERVER_URL}/api/Houses`
  const response = await axios.post(url, house)
  return response.data
}

export const addMember = async (member: any, cb: () => void) => {
  const url = `${SERVER_URL}/api/HouseMembers`
  const response = await axios.post(url, member)
  cb()
  return response.data
}

export const putMember = async (member: any, cb: () => void) => {
  const url = `${SERVER_URL}/api/HouseMembers`
  const response = await axios.put(url, member)
  cb()
  return response.data
}

const map = {}
export const addRecord = async (record: CsvRecord, cb: () => void) => {
  const converted = convertFromCsv(record)
  if (map[converted.id]) {
    console.log(converted.id + ' already exists')
  }
  map[converted.id] = 1
  return putMember(converted, cb)
}

export const importCsvRecords = async (
  records: CsvRecord[],
  onProgress: (addedCount: number) => void
) => {
  let addedCount = 0
  function onAdded() {
    addedCount++
    onProgress(addedCount)
  }

  const date = moment().format('YYYY/MM/DD H:mm')
  const house = {
    id: uuid(),
    houseNo: `Import ${date}`,
    editor: userStore.name,
    editorId: userStore.id,
  }

  const addedHouse = await addHouse(house)

  return Promise.map(
    records,
    function (record) {
      const recordWithHouseId = {
        ...record,
        houseId: addedHouse.id,
      }
      return addRecord(recordWithHouseId, onAdded)
    },
    {
      concurrency: 5,
    }
  )
}

function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
