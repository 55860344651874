import { MemberDetail } from './HomePage/HouseMember'
import React from 'react'

export const TestPage = (props) => {
  function onChange(e) {
    console.log('onChange: ', e)
  }

  return (
    <section>
      <h3>Test</h3>
      <MemberDetail></MemberDetail>
    </section>
  )
}
