import { MemberListItem } from './MemberListItem'
import React from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

const Container = styled.div`
  height: 100%;
  overflow: scroll;
`

export const MemberList = observer((props) => {
  const { members = [], onMemberItemClick, selectedMemberId } = props

  return (
    <Container>
      {members.map((member, index) => {
        const selected = member.id === selectedMemberId

        return (
          <MemberListItem
            item={member}
            index={index + 1}
            key={member.id}
            selected={selected}
            onMemberItemClick={onMemberItemClick}
          >
            {member.Name}
          </MemberListItem>
        )
      })}
    </Container>
  )
})
