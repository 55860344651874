import * as api from './api'

import {
  CommuneSelector,
  DistrictSelector,
  ProvinceSelector,
  Spinner,
  VillageSelector,
} from 'Components'
import { Input, Modal, message } from 'antd'
import React, { useEffect, useState } from 'react'

import { MemberDetail } from '../HomePage/HouseMember/MemberDetail/MemberDetail'
import { MemberEditContainer } from '../HomePage/HouseMember/MemberEdit/MemberEditContainer'
import { MemberList } from './MemberList'
import { RegionEntity } from 'Components/GeoSelector/api'
import { SearchOutlined } from '@ant-design/icons'
import { memberStore } from 'Models/HouseMemberModel'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

interface Props {}

const Container = styled.section`
  padding: 20px;
`

const Row = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledProvinceSelector = styled(ProvinceSelector)`
  width: 150px;
`

const StyledDistrictSelector = styled(DistrictSelector)`
  width: 150px;
`

const StyledCommuneSelector = styled(CommuneSelector)`
  width: 150px;
`

const StyledVillageSelector = styled(VillageSelector)`
  width: 150px;
`

const Label = styled.div`
  margin-left: 10px;
  margin-right: 4px;
`

export const MemberPage = observer((props: Props) => {
  const [province, setProvince] = useState<RegionEntity>()
  const [district, setDistrict] = useState<RegionEntity>()
  const [commune, setCommune] = useState<RegionEntity>()
  const [village, setVillage] = useState<RegionEntity>()
  const [nameFilter, setNameFilter] = useState('')
  const [loading, setLoading] = useState(false)
  const [shouldShowMemberDetail, setShouldShowMemberDetail] = useState(false)
  const [shouldShowEditMemberModal, setShouldShowEditMemberModal] = useState(
    false
  )

  const { members } = memberStore

  useEffect(() => {
    let where: any = {
      name: {
        like: `^${nameFilter}`,
        options: 'i',
      },
    }
    if (province) {
      where.province = province.name.km
    }

    if (district) {
      where.district = district.name.km
    }

    if (commune) {
      where.commune = commune.name.km
    }

    if (village) {
      where.village = village.name.km
    }

    const filter = {
      where,
      limit: 2000,
    }

    async function fetchMembers() {
      setLoading(true)
      try {
        await memberStore.loadMembersByFilter(filter)
      } catch (e) {
        message.error(e.message)
      }
      setLoading(false)
    }

    fetchMembers()
  }, [province, district, commune, village, nameFilter])

  function onProvinceSelected(province: RegionEntity) {
    setProvince(province)
  }

  function onDistrictSelected(district: RegionEntity) {
    setDistrict(district)
  }

  function onCommuneSelected(commune: RegionEntity) {
    setCommune(commune)
  }

  function onVillageSelected(village: RegionEntity) {
    setVillage(village)
  }

  async function onNameFilterChange(e) {
    const text = e.target.value
    setNameFilter(text)
  }

  function onMemberClick(member: any) {
    memberStore.setSelectedMember(member.id)
    setShouldShowMemberDetail(true)
  }

  function onEditButtonClick() {
    setShouldShowEditMemberModal(true)
  }

  return (
    <Container>
      <Row>
        <Label>ខេត្ត:</Label>
        <StyledProvinceSelector onSelected={onProvinceSelected} />
        <Label>ស្រុក:</Label>
        <StyledDistrictSelector
          provinceId={province && province.id}
          onSelected={onDistrictSelected}
        />
        <Label>ឃុំ:</Label>
        <StyledCommuneSelector
          onSelected={onCommuneSelected}
          districtId={district && district.id}
        />
        <Label>ភូមិ:</Label>
        <StyledVillageSelector
          communeId={commune && commune.id}
          onSelected={onVillageSelected}
        />
      </Row>
      <Row>
        <Label>Name:</Label>
        <Input
          size="middle"
          placeholder="Name"
          onChange={onNameFilterChange}
          style={{ width: 150 }}
        />
      </Row>
      {loading && <Spinner></Spinner>}
      <MemberList
        members={members.slice()}
        onMemberClick={onMemberClick}
      ></MemberList>

      {shouldShowMemberDetail && (
        <Modal
          visible={shouldShowMemberDetail}
          onCancel={() => setShouldShowMemberDetail(false)}
          footer={[]}
          width={1000}
        >
          <MemberDetail onEditButtonClick={onEditButtonClick}></MemberDetail>
        </Modal>
      )}

      {shouldShowEditMemberModal && (
        <Modal
          visible={shouldShowEditMemberModal}
          onCancel={() => setShouldShowEditMemberModal(false)}
          footer={[]}
          width={1000}
        >
          <MemberEditContainer
            onSaveFinish={() => setShouldShowEditMemberModal(false)}
          ></MemberEditContainer>
        </Modal>
      )}
    </Container>
  )
})
