import * as Colors from 'Constants/Colors'

import { Form, message } from 'antd'
import React, { useEffect, useState } from 'react'

import { HouseAddForm } from '../HouseAdd/HouseAddForm'
import { MapPin } from '../HouseAdd/MapPin'
import { houseStore } from 'Models/HouseModel'
import styled from 'styled-components'

const Container = styled.section``

const Title = styled.h4`
  background: ${Colors.PrimaryColor};
  padding: 10px;
  margin-bottom: 30px;
`

export const HouseEditContainer = (props) => {
  const { onSaveFinish, lastPinnedLocation } = props
  const { selectedHouse = {} } = houseStore
  const [pinnedLocation, setPinnedLocation] = useState(undefined)
  const [showMapPin, setShowMapPin] = useState(false)

  const [form] = Form.useForm()
  const [saving, setSaving] = useState(false)

  const initialValues = selectedHouse

  useEffect(() => {
    if (initialValues.geometry?.lat) {
      const lat = initialValues.geometry.lat
      const lng = initialValues.geometry.lng

      setPinnedLocation({
        lat,
        lng,
      })
    }
  }, [])

  async function onSubmit(values) {
    console.log('onSubmit: ', values)
    setSaving(true)
    const hideMessage = message.loading('កំពុងរក្សាទុក', 0)

    const dataToSave = {
      ...values,
      geometry: pinnedLocation,
    }
    try {
      await selectedHouse.edit(dataToSave)
      form.resetFields()
      setSaving(false)
      hideMessage()
      onSaveFinish && onSaveFinish()
    } catch (e) {
      setSaving(false)
      hideMessage()
      message.error(e.message)
    }
  }

  function onShowMapButtonPress() {
    setShowMapPin(true)
  }

  function onMapPinDoneButtonPress() {
    setShowMapPin(false)
  }

  function onMapLocationSelected(location) {
    setPinnedLocation(location)
  }

  return (
    <Container>
      <Title>កែប្រែពត៌មានផ្ទះ</Title>
      {showMapPin ? (
        <MapPin
          onDoneButtonPress={onMapPinDoneButtonPress}
          onLocationSelected={onMapLocationSelected}
          selectedLocation={pinnedLocation}
          lastPinnedLocation={lastPinnedLocation}
        ></MapPin>
      ) : (
        <HouseAddForm
          onSubmit={onSubmit}
          onShowMapButtonPress={onShowMapButtonPress}
          saving={saving}
          form={form}
          initialValues={initialValues}
          pinnedLocation={pinnedLocation}
        ></HouseAddForm>
      )}
    </Container>
  )
}
