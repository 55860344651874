import React, { useEffect, useState } from 'react'

import { Map } from 'Components'
import { Marker } from '@react-google-maps/api'
import dotIcon from './assets/dot.png'
import { houseStore } from 'Models/HouseModel'
import { observer } from 'mobx-react-lite'
import selectedDotIcon from './assets/selected_dot.png'
import styled from 'styled-components'

export const HouseMap = observer((props) => {
  const { houses, selectedHouse = {} } = houseStore
  const [map, setMap] = useState()

  useEffect(() => {
    if (map && selectedHouse.geometry && selectedHouse.geometry.x) {
      const point = {
        lat: selectedHouse.geometry.y,
        lng: selectedHouse.geometry.x,
      }
      map.panTo(point)
    }
  }, [selectedHouse])

  function onMapLoaded(map) {
    setMap(map)
  }

  return (
    <Map onLoad={onMapLoaded}>
      {houses.map((house) => {
        if (!house.geometry) {
          return null
        }

        const isSelected = house.id === selectedHouse.id
        const icon = isSelected ? selectedDotIcon : dotIcon
        const iconSize = isSelected ? 16 : 8
        return (
          <Marker
            key={house.id}
            position={{
              lat: house.geometry?.y,
              lng: house.geometry?.x,
            }}
            icon={{
              url: icon,
              scaledSize: {
                width: iconSize,
                height: iconSize,
              },
            }}
          ></Marker>
        )
      })}
    </Map>
  )
})
