import { Button } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import React from 'react'

export const EditButton = (props) => {
  function onClick(e) {
    e.stopPropagation()
    props.onClick && props.onClick()
  }

  return (
    <Button onClick={onClick} shape="circle" icon={<EditOutlined />}></Button>
  )
}
