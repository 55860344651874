import { HouseListItem } from './HouseListItem'
import React from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

const Container = styled.section`
  overflow: scroll;
  height: 100%;
`

export const HouseList = observer((props) => {
  const { houses = [], selectedHouseId, onHouseItemClick, onEditClick } = props
  return (
    <Container>
      {houses
        .slice()
        .reverse()
        .map((house) => {
          return (
            <HouseListItem
              key={house.id}
              selected={house.id === selectedHouseId}
              item={house}
              onClick={() => onHouseItemClick(house)}
              onEditClick={onEditClick}
            ></HouseListItem>
          )
        })}
    </Container>
  )
})
