import * as api from 'Components/GeoSelector/api'

import { Button, Divider, Form, Input, Select } from 'antd'
import {
  CommuneSelector,
  DistrictSelector,
  ProvinceSelector,
  VillageSelector,
} from 'Components'
import React, { useEffect, useState } from 'react'

import { ImageUpload } from 'Components/ImageUpload'
import MaskedInput from 'antd-mask-input'
import { RegionEntity } from 'Components/GeoSelector/api'
import moment from 'moment'

var MemberRelationOptions = [
  { value: 'មេគ្រួសារ', label: 'មេគ្រួសារ' },
  { value: 'តា', label: 'តា' },
  { value: 'យាយ', label: 'យាយ' },
  { value: 'ឳពុក', label: 'ឳពុក' },
  { value: 'ម្ដាយ', label: 'ម្ដាយ' },
  { value: 'ប្ដី', label: 'ប្ដី' },
  { value: 'ប្រពន្ធ', label: 'ប្រពន្ធ' },
  { value: 'បងប្អូន', label: 'បងប្អូន' },
  { value: 'កូន', label: 'កូន' },
  { value: 'ចៅ', label: 'ចៅ' },
  { value: 'ផ្សេងៗ', label: 'ផ្សេងៗ' },
]

const { Option } = Select

const validateBirthDate = (rule, value) => {
  if (!value) {
    return Promise.resolve()
  }
  if (value.indexOf('_') > -1) {
    return Promise.reject('មិនត្រូវមានសញ្ញា _')
  }

  const date = moment(value, 'DD/MM/YYYY')

  const earliestDate = moment('31/12/1900', 'DD/MM/YYYY')
  if (date.isBefore(earliestDate)) {
    return Promise.reject('មិនអាចមុនថ្ងៃ 31/12/1900 ទេ')
  }

  const now = moment()
  if (date.isAfter(now)) {
    return Promise.reject('មិនអាចក្រោយថ្ងៃនេះទេ')
  }

  return Promise.resolve()
}

export const MemberForm = (props) => {
  const {
    onSubmit,
    saving,
    form,
    onFileUploaded,
    uploadedPhotoUrl,
    initialValues,
  } = props

  const [province, setProvince] = useState<RegionEntity>()
  const [district, setDistrict] = useState<RegionEntity>()
  const [commune, setCommune] = useState<RegionEntity>()

  useEffect(() => {
    const {
      province: provinceName,
      district: districtName,
      commune: communeName,
    } = initialValues

    if (provinceName) {
      setProvinceByName(provinceName)
    }

    if (districtName) {
      setDistrictByName(districtName)
    }

    if (communeName) {
      setCommuneByName(communeName)
    }
  }, [])

  async function setProvinceByName(name: string) {
    const province = await api.getProvinceByName(name)
    setProvince(province)
  }

  async function setDistrictByName(name: string) {
    const district = await api.getDistrictByName(name)
    setDistrict(district)
  }

  async function setCommuneByName(name: string) {
    const commune = await api.getCommuneByName(name)
    console.log('commune: ', commune)
    setCommune(commune)
  }

  function onProvinceSelected(province: RegionEntity) {
    setProvince(province)
  }

  function onDistrictSelected(district: RegionEntity) {
    setDistrict(district)
  }

  function onCommuneSelected(commune: RegionEntity) {
    setCommune(commune)
  }

  return (
    <Form
      onFinish={onSubmit}
      size="large"
      labelCol={{ span: 7 }}
      form={form}
      initialValues={initialValues}
    >
      <Form.Item label="រូបថត">
        <ImageUpload
          onFileUploaded={onFileUploaded}
          uploadedPhotoUrl={uploadedPhotoUrl}
        ></ImageUpload>
      </Form.Item>

      <Form.Item
        label="ឈ្មោះ(2)"
        name="name"
        rules={[{ required: true, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="ឈ្មោះឡាតាំង(ថែមថ្មី)"
        name="nameLatin"
        rules={[{ required: false, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="ភេទ(3)"
        name="gender"
        rules={[{ required: true, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <Select>
          <Option value="ប្រុស">ប្រុស</Option>
          <Option value="ស្រី">ស្រី</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="ថ្ងៃខែឆ្នាំកំណើត (DD/MM/YYYY) (4)"
        name="dateOfBirth"
        rules={[{ validator: validateBirthDate }]}
      >
        <MaskedInput mask="11/11/1111" size="middle" />
      </Form.Item>

      <Form.Item
        label="ថ្ងៃខែឆ្នាំចូលបក្ស (DD/MM/YYYY) (5)"
        name="dateOfMember"
        rules={[{ required: false, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <MaskedInput mask="11/11/1111" size="middle" />
      </Form.Item>

      <Form.Item
        label="ទីកន្លែងកំណើត (រាជធានី/ខេត្ត) (6)"
        name="placeOfBirth"
        rules={[{ required: false, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="រាជធានី/ខេត្ត (10)"
        name="province"
        rules={[{ required: true, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <ProvinceSelector onSelected={onProvinceSelected} />
      </Form.Item>

      <Form.Item
        label="ក្រុង/ស្រុក/ខណ្ឌ (9)"
        name="district"
        rules={[{ required: false, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <DistrictSelector
          provinceId={province && province.id}
          onSelected={onDistrictSelected}
        />
      </Form.Item>

      <Form.Item
        label="ឃុំ/សង្កាត់ (8)"
        name="commune"
        rules={[{ required: false, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <CommuneSelector
          onSelected={onCommuneSelected}
          districtId={district && district.id}
        />
      </Form.Item>

      <Form.Item
        label="ភូមិ (7)"
        name="village"
        rules={[{ required: false, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <VillageSelector communeId={commune && commune.id} />
      </Form.Item>

      <Form.Item
        label="លេខអត្តសញ្ញាណប័ណ្ឌ (11)"
        name="idNo"
        rules={[{ max: 9, message: 'ត្រឹម9ខ្ទង់ចុះ' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="លេខការិយាល័យបោះឆ្នោត (12)"
        name="pollingOfficeNo"
        rules={[{ max: 4, message: 'ត្រឹម4ខ្ទង់ចុះ' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="តួនាទី/មុខរបរ (13)"
        name="job"
        rules={[{ required: true, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="តួនាទីក្នុងបក្ស (14)"
        name="roleInParty"
        rules={[{ required: true, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="កម្រិតវប្បធម៌​ (15)"
        name="educationLevel"
        rules={[{ required: false, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="មានប័ណ្ណសមាជិកបក្ស (16)"
        name="hasMemberCard"
        rules={[{ required: false, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <Select>
          <Option value={1}>មាន</Option>
          <Option value={0}>គ្មាន</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="លេខទូរស័ព្ទ (ថែមថ្មី)"
        name="phoneNo"
        rules={[
          { required: true, message: 'សូមបញ្ចូលតម្លៃ' },
          { max: 10, message: 'ត្រឹម១០ខ្ទង់ចុះ' },
          { min: 9, message: 'ចាប់ពី៩ខ្ទង់ឡើង' },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="លេខប័ណ្ណសមាជិកបក្ស"
        name="memberNo"
        rules={[{ max: 6, message: 'ត្រឹម6ខ្ទង់ចុះ' }]}
      >
        <Input />
      </Form.Item>

      <Divider></Divider>

      <Form.Item
        label="ទំនាក់ទំនងក្នុងគ្រួសារបក្ស"
        name="memberRelation"
        rules={[{ required: false, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <Select>
          {MemberRelationOptions.map((option) => {
            return (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item
        label="អ៊ីមែល"
        name="email"
        rules={[{ required: false, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="ឈ្មោះក្នុងហ្វេសប៊ុក"
        name="facebookName"
        rules={[{ required: false, message: 'សូមបញ្ចូលតម្លៃ' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 5 }}>
        <Button type="primary" htmlType="submit" loading={saving}>
          រក្សារទុក
        </Button>
      </Form.Item>
    </Form>
  )
}
