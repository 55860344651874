import 'antd/dist/antd.css'
import './App.css'

import {
  HomePage,
  ImportMemberPage,
  LoginPage,
  MemberPage,
  TestPage,
} from 'Pages'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { HeaderSection } from 'Header'
import { Layout } from 'antd'
import { PrivateRoute } from 'Auth'
import React from 'react'
import { observer } from 'mobx-react-lite'

const { Content } = Layout

const App = observer(() => {
  return (
    <Router>
      <Layout>
        <HeaderSection></HeaderSection>
        <Content
          style={{
            padding: '0 0',
            marginTop: 0,
            backgroundColor: 'white',
            position: 'relative',
          }}
        >
          <Switch>
            <Route exact path="/login" component={LoginPage} />

            <PrivateRoute exact path="/">
              <HomePage></HomePage>
            </PrivateRoute>

            <PrivateRoute exact path="/home">
              <HomePage></HomePage>
            </PrivateRoute>

            <PrivateRoute exact path="/import-member">
              <ImportMemberPage></ImportMemberPage>
            </PrivateRoute>

            <PrivateRoute exact path="/members">
              <MemberPage></MemberPage>
            </PrivateRoute>

            <Route exact path="/test" component={TestPage} />
          </Switch>
        </Content>
      </Layout>
    </Router>
  )
})

export default App
