import * as Colors from 'Constants/Colors'

import { Button } from 'antd'
import React from 'react'
import { memberStore } from 'Models/HouseMemberModel'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

const Container = styled.section`
  margin-left: 30px;
  padding: 20px;
  padding-top: 0px;
  height: 100%;
  width: 100%;
  overflow: scroll;
`

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  background: ${Colors.SecondaryColor};
  padding-left: 20px;
  height: 53px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid lightgray;
  padding: 15px;
`

const Label = styled.div`
  width: 170px;
  color: gray;
`

const Value = styled.div``

const ATTRIBUTES = [
  { key: 'name', label: 'ឈ្មោះ', type: 'string' },
  { key: 'nameLatin', label: 'ឈ្មោះឡាតាំង', type: 'string' },
  { key: 'gender', label: 'ភេទ', type: 'string' },
  { key: 'dateOfBirth', label: 'ថ្ងៃខែឆ្នាំកំណើត', type: 'date' },
  { key: 'dateOfMember', label: 'ថ្ងៃខែឆ្នាំចូលបក្ស', type: 'date' },
  { key: 'placeOfBirth', label: 'ទីកន្លែងកំណើត', type: 'string' },
  { key: 'village', label: 'ភូមិ', type: 'string' },
  { key: 'commune', label: 'ឃុំ/សង្កាត់', type: 'string' },
  { key: 'district', label: 'ស្រុក/ខណ្ឌ', type: 'string' },
  { key: 'province', label: 'រាជធានី/ខេត្ត', type: 'string' },
  { key: 'idNo', label: 'លេខអត្តសញ្ញាណប័ណ្ឌ', type: 'string' },
  { key: 'pollingOfficeNo', label: 'លេខការិយាល័យបោះឆ្នោត', type: 'string' },
  { key: 'job', label: 'តួនាទី/មុខរបរ', type: 'string' },
  { key: 'roleInParty', label: 'តួនាទីក្នុងបក្ស', type: 'string' },
  { key: 'educationLevel', label: 'កម្រិតវប្បធម៌', type: 'string' },
  { key: 'hasMemberCard', label: 'មានប័ណ្ណសមាជិក', type: 'boolean' },
  { key: 'memberNo', label: 'លេខប័ណ្ណសមាជិកបក្ស', type: 'string' },
  { key: 'phoneNo', label: 'លេខទូរស័ព្ទ', type: 'string' },
  {
    key: 'memberRelation',
    label: 'ទំនាក់ទំនងក្នុងគ្រួសារបក្ស',
    type: 'string',
  },
  { key: 'email', label: 'អ៊ីមែល', type: 'string' },
  { key: 'facebookName', label: 'ឈ្មោះក្នុងហ្វេសប៊ុក', type: 'string' },
]

interface Props {
  onEditButtonClick?: () => void
}

export const MemberDetail = observer((props: Props) => {
  const { selectedMember = {} } = memberStore
  console.log('selectedMember: ', selectedMember)
  const { onEditButtonClick } = props

  function booleanToString(value) {
    if (value === true) {
      return 'មាន'
    }
    return 'គ្មាន'
  }

  return (
    <Container>
      <TitleRow>
        <h4>ពត៌មានលម្អិត</h4>
        <Button onClick={onEditButtonClick} type="primary">
          កែប្រែ
        </Button>
      </TitleRow>

      {ATTRIBUTES.map((attribute) => {
        return (
          <Row key={attribute.key}>
            <Label>{attribute.label}:</Label>
            <Value>
              {attribute.type === 'date'
                ? selectedMember[attribute.key] &&
                  moment(selectedMember[attribute.key]).format('DD/MM/YYYY')
                : attribute.type === 'boolean'
                ? booleanToString(selectedMember[attribute.key])
                : selectedMember[attribute.key]}
            </Value>
          </Row>
        )
      })}
    </Container>
  )
})
