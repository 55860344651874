import * as Colors from 'Constants/Colors'

import React, { useState } from 'react'

import { Form } from 'antd'
import { HouseAddForm } from './HouseAddForm'
import { MapPin } from './MapPin'
import { houseStore } from 'Models/HouseModel'
import styled from 'styled-components'

const Container = styled.section`
  height: 100%;
`

const Title = styled.h4`
  background: ${Colors.PrimaryColor};
  padding: 10px;
  margin-bottom: 30px;
`

export const HouseAddContainer = (props) => {
  const { onSaveFinish, lastPinnedLocation } = props
  const [showMapPin, setShowMapPin] = useState(false)
  const [pinnedLocation, setPinnedLocation] = useState(undefined)
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()

  async function onSubmit(values) {
    console.log('onSubmit: ', values)
    setSaving(true)

    const house = {
      ...values,
      geometry: pinnedLocation,
    }
    console.log('addHouse: ', house)
    try {
      const addedHouse = await houseStore.addHouse(house)
      setSaving(false)
      if (pinnedLocation) {
        props.onMapPinned && props.onMapPinned(pinnedLocation)
      }
      resetMapPinLocation()
      houseStore.setSelectedHouse(addedHouse.id)
      form.resetFields()
      onSaveFinish && onSaveFinish()
    } catch (e) {
      alert('Cannot add house ' + e.message)
      setSaving(false)
    }
  }

  function resetMapPinLocation() {
    setPinnedLocation(undefined)
  }

  function onShowMapButtonPress() {
    setShowMapPin(true)
  }

  function onMapPinDoneButtonPress() {
    setShowMapPin(false)
  }

  function onMapLocationSelected(location) {
    setPinnedLocation(location)
  }

  return (
    <Container>
      <Title>បន្ថែមទិន្ន័យផ្ទះ</Title>
      {showMapPin ? (
        <MapPin
          onDoneButtonPress={onMapPinDoneButtonPress}
          onLocationSelected={onMapLocationSelected}
          selectedLocation={pinnedLocation}
          lastPinnedLocation={lastPinnedLocation}
        ></MapPin>
      ) : (
        <HouseAddForm
          form={form}
          onSubmit={onSubmit}
          onShowMapButtonPress={onShowMapButtonPress}
          saving={saving}
          pinnedLocation={pinnedLocation}
        ></HouseAddForm>
      )}
    </Container>
  )
}
