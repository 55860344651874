import * as Colors from 'Constants/Colors'

import { Button, Spin, message } from 'antd'
import React, { useEffect, useState } from 'react'

import { HouseList } from './HouseList'
import { houseStore } from 'Models/HouseModel'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

const Container = styled.section`
  height: 100%;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  border-bottom: 1px solid lightgray;
  background: ${Colors.PrimaryColor};
  height: 53px;
`

const Spinner = styled(Spin)`
  margin: 30px;
`

export const HouseListContainer = observer((props) => {
  const { onAddButtonPress, onHouseItemClick, onEditClick } = props
  const { houses, selectedHouse = {} } = houseStore
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function load() {
      setLoading(true)
      try {
        await houseStore.loadHouses()
      } catch (e) {
        message.error(e.message)
      }
      setLoading(false)
    }
    load()
  }, [])
  return (
    <Container>
      <Row>
        <h4>បញ្ជីផ្ទះ</h4>
        <Button onClick={onAddButtonPress} type="primary">
          បន្ថែម
        </Button>
      </Row>

      {loading ? (
        <Spinner size="large"></Spinner>
      ) : (
        <HouseList
          houses={houses}
          selectedHouseId={selectedHouse.id}
          onHouseItemClick={onHouseItemClick}
          onEditClick={onEditClick}
        ></HouseList>
      )}
    </Container>
  )
})
