import * as api from './api'

import { DeleteConfirmButton, EditButton } from 'Components'
import { Modal, message } from 'antd'
import React, { useState } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const { confirm } = Modal

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
  background: ${(props) => (props.selected ? '#00cec9' : 'white')};

  &:hover {
    background: #00cec9;
  }
`

const DeleteButton = styled(DeleteConfirmButton)``

const Info = styled.div`
  margin-left: 5px;
`

const Controls = styled.div`
  position: absolute;
  right: 0;
  width: 108px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  visibility: hidden;
  ${Container}:hover & {
    visibility: visible;
  }
`

export const HouseListItem = (props) => {
  const { item, onEditClick } = props
  const [deleting, setDeleting] = useState(false)

  async function onDeleteConfirmClick() {
    confirm({
      title: 'Also delete members?',
      icon: <ExclamationCircleOutlined />,
      content: 'Do you want to also delete members of this house?',
      okText: 'Yes delete members too',
      okType: 'danger',
      cancelText: 'No, delete only house',
      onOk() {
        deleteHouseAndMembers()
      },
      onCancel() {
        deleteOnlyHouse()
      },
    })
  }

  async function deleteOnlyHouse() {
    setDeleting(true)
    try {
      await item.delete()
      setDeleting(false)
    } catch (e) {
      message.error(e.message)
      setDeleting(false)
    }
  }

  async function deleteHouseAndMembers() {
    setDeleting(true)
    try {
      await api.deleteMembersOfAHouse(item.id)
      await item.delete()
      setDeleting(false)
    } catch (e) {
      message.error(e.message)
    }
    setDeleting(false)
  }

  return (
    <Container {...props}>
      <Info>
        {item.houseNo} {item.streetNo} {item.province} {item.district}{' '}
        {item.commune} {item.village}
      </Info>
      <Controls>
        <EditButton onClick={() => onEditClick(item)}></EditButton>
        <DeleteButton
          deleting={deleting}
          onConfirmClick={onDeleteConfirmClick}
        ></DeleteButton>
      </Controls>
    </Container>
  )
}
