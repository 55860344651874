import * as api from 'Components/GeoSelector/api'

import { Button, Form, Input } from 'antd'
import {
  CommuneSelector,
  DistrictSelector,
  Map,
  ProvinceSelector,
  VillageSelector,
} from 'Components'
import React, { useEffect, useState } from 'react'

import { Marker } from '@react-google-maps/api'
import { RegionEntity } from 'Components/GeoSelector/api'
import styled from 'styled-components'

const MapBox = styled.div`
  margin-left: 80px;
  width: 400px;
  height: 200px;
  background: orange;
`

export const HouseAddForm = (props) => {
  const {
    onSubmit,
    onShowMapButtonPress,
    saving,
    form,
    pinnedLocation,
    initialValues = {},
  } = props

  const [province, setProvince] = useState<RegionEntity>()
  const [district, setDistrict] = useState<RegionEntity>()
  const [commune, setCommune] = useState<RegionEntity>()

  useEffect(() => {
    const {
      province: provinceName,
      district: districtName,
      commune: communeName,
    } = initialValues

    if (provinceName) {
      setProvinceByName(provinceName)
    }

    if (districtName) {
      setDistrictByName(districtName)
    }

    if (communeName) {
      setCommuneByName(communeName)
    }
  }, [])

  async function setProvinceByName(name: string) {
    const province = await api.getProvinceByName(name)
    setProvince(province)
  }

  async function setDistrictByName(name: string) {
    const district = await api.getDistrictByName(name)
    setDistrict(district)
  }

  async function setCommuneByName(name: string) {
    const commune = await api.getCommuneByName(name)
    console.log('commune: ', commune)
    setCommune(commune)
  }

  function onProvinceSelected(province: RegionEntity) {
    setProvince(province)
  }

  function onDistrictSelected(district: RegionEntity) {
    setDistrict(district)
  }

  function onCommuneSelected(commune: RegionEntity) {
    setCommune(commune)
  }

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      size="large"
      labelCol={{ span: 2 }}
      initialValues={initialValues}
    >
      {pinnedLocation && (
        <MapBox>
          <Map center={pinnedLocation}>
            <Marker position={pinnedLocation}></Marker>
          </Map>
        </MapBox>
      )}

      <Form.Item wrapperCol={{ offset: 2 }}>
        <Button onClick={onShowMapButtonPress}>ដៅទីតាំង</Button>
      </Form.Item>

      <Form.Item
        label="លេខផ្ទះ"
        name="houseNo"
        rules={[{ required: false, message: 'Please input a value' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="លេខផ្លូវ"
        name="streetNo"
        rules={[{ required: false, message: 'Please input a value' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="ខេត្ត"
        name="province"
        rules={[{ required: true, message: 'Please input a value' }]}
      >
        <ProvinceSelector onSelected={onProvinceSelected} />
      </Form.Item>

      <Form.Item
        label="ស្រុក"
        name="district"
        rules={[{ required: true, message: 'Please input a value' }]}
      >
        <DistrictSelector
          provinceId={province && province.id}
          onSelected={onDistrictSelected}
        />
      </Form.Item>

      <Form.Item
        label="ឃុំ"
        name="commune"
        rules={[{ required: true, message: 'Please input a value' }]}
      >
        <CommuneSelector
          onSelected={onCommuneSelected}
          districtId={district && district.id}
        />
      </Form.Item>

      <Form.Item
        label="ភូមិ"
        name="village"
        rules={[{ required: true, message: 'Please input a value' }]}
      >
        <VillageSelector communeId={commune && commune.id} />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 2 }}>
        <Button type="primary" htmlType="submit" loading={saving}>
          រក្សារទុក
        </Button>
      </Form.Item>
    </Form>
  )
}
