import { Layout, Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import Logo from '../logo.png'
import styled from 'styled-components'
import { userStore } from 'Models/UserModel'

const LogoImage = styled.img`
  height: 45px;
`

const { Header } = Layout
const { SubMenu } = Menu

export const HeaderSection = (props) => {
  const [selectedKey, setSelectedKey] = useState('/route-property')
  let history = useHistory()
  let location = useLocation()

  useEffect(() => {
    const { pathname } = location
    setSelectedKey(pathname)
  }, [])

  function onMenuItemClick(key) {
    setSelectedKey(key)
    history.push(key)
  }

  function onLogoutClick() {
    userStore.logout()
  }

  return (
    <Header style={{ zIndex: 1, width: '100%' }}>
      <Menu theme="dark" mode="horizontal" selectedKeys={selectedKey}>
        <Menu.Item onClick={() => onMenuItemClick('/')}>
          <LogoImage src={Logo} alt="logo" />
        </Menu.Item>

        <Menu.Item key="/home" onClick={() => onMenuItemClick('/home')}>
          <div onClick={() => onMenuItemClick('/home')}>ផ្ទះ</div>
        </Menu.Item>

        <Menu.Item key="/members" onClick={() => onMenuItemClick('/members')}>
          <div onClick={() => onMenuItemClick('/members')}>សមាជិក</div>
        </Menu.Item>

        <Menu.Item
          key="/import-member"
          onClick={() => onMenuItemClick('/import-member')}
        >
          <div onClick={() => onMenuItemClick('/import-member')}>Import</div>
        </Menu.Item>

        <SubMenu style={{ float: 'right' }} key="user" title={userStore.name}>
          <Menu.Item onClick={onLogoutClick} key="logout">
            Logout
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Header>
  )
}
