import { SERVER_URL } from 'Constants/Server'
import axios from 'axios'

export const addHouse = async (house) => {
  const url = `${SERVER_URL}/api/Houses`
  const response = await axios.post(url, house)
  return response.data
}

export const getHouses = async (userName) => {
  const filter = {
    where: {
      editor: userName,
    },
  }
  const url = `${SERVER_URL}/api/Houses?filter=${JSON.stringify(filter)}`
  const response = await axios.get(url)
  return response.data
}

export const deleteHouseById = async (id) => {
  const url = `${SERVER_URL}/api/Houses/${id}`
  const response = await axios.delete(url)
  return response.data
}

export const editHouse = async (values) => {
  const url = `${SERVER_URL}/api/Houses/${values.id}`
  const data = values
  const response = await axios.patch(url, data)
  return response.data
}
