import { Form, message } from 'antd'
import React, { useEffect, useState } from 'react'

import { MemberForm } from '../MemberAdd/MemberForm'
import { memberStore } from 'Models/HouseMemberModel'
import moment from 'moment'
import styled from 'styled-components'

const Container = styled.section``

export const MemberEditContainer = (props) => {
  const { onSaveFinish } = props
  const { selectedMember } = memberStore
  const values = selectedMember ? selectedMember : {}
  const [form] = Form.useForm()
  const [saving, setSaving] = useState(false)
  const [uploadedPhotoUrl, setUploadedPhotoUrl] = useState()

  const DateOfMember = values.DateOfMember
    ? moment(values.DateOfMember).format('DD/MM/YYYY')
    : undefined

  const DateOfBirth = values.DateOfBirth
    ? moment(values.DateOfBirth).format('DD/MM/YYYY')
    : undefined

  const initialValues = {
    ...values,
    DateOfMember,
    DateOfBirth,
  }

  useEffect(() => {
    setUploadedPhotoUrl(initialValues.Photo)
  }, [])

  function onPhotoUploaded(url) {
    setUploadedPhotoUrl(url)
  }

  async function onSubmit(values) {
    console.log('onSubmit: ', values)
    setSaving(true)
    const hideMessage = message.loading('កំពុងរក្សាទុក', 0)

    const dataToSave = {
      ...values,
      photo: uploadedPhotoUrl,
    }
    try {
      await selectedMember.edit(dataToSave)
      form.resetFields()
      setUploadedPhotoUrl(undefined)
      setSaving(false)
      hideMessage()
      onSaveFinish && onSaveFinish()
    } catch (e) {
      setSaving(false)
      hideMessage()
      message.error(e.message)
    }
  }

  return (
    <Container>
      <h4>កែប្រែពត៌មានសមាជិក</h4>
      <MemberForm
        onSubmit={onSubmit}
        saving={saving}
        form={form}
        initialValues={initialValues}
        onFileUploaded={onPhotoUploaded}
        uploadedPhotoUrl={uploadedPhotoUrl}
      ></MemberForm>
    </Container>
  )
}
