import { Button } from 'antd'
import { Map } from 'Components'
import { Marker } from '@react-google-maps/api'
import React from 'react'
import dotIcon from './dot.png'
import { houseStore } from 'Models/HouseModel'
import styled from 'styled-components'

const Container = styled.section`
  height: 600px;
`
export const MapPin = (props) => {
  const {
    onDoneButtonPress,
    selectedLocation,
    onLocationSelected,
    lastPinnedLocation,
  } = props
  console.log('selectedLocation: ', selectedLocation)

  const { houses } = houseStore

  function onMapClick(event) {
    const lat = event.latLng.lat()
    const lng = event.latLng.lng()
    const location = {
      lat,
      lng,
    }

    onLocationSelected && onLocationSelected(location)
  }
  return (
    <Container>
      <Map
        onClick={onMapClick}
        mapContainerStyle={{
          height: '80%',
        }}
        center={lastPinnedLocation}
      >
        {selectedLocation && <Marker position={selectedLocation}></Marker>}
        {houses.map((house) => {
          if (!house.geometry || !house.geometry.lat) {
            return null
          }

          return (
            <Marker
              key={house.id}
              position={{
                lat: house.geometry?.lat,
                lng: house.geometry?.lng,
              }}
              icon={{
                url: dotIcon,
                scaledSize: {
                  width: 8,
                  height: 8,
                },
              }}
            ></Marker>
          )
        })}
      </Map>
      <Button type="primary" size="large" onClick={onDoneButtonPress}>
        រួចរាល់
      </Button>
    </Container>
  )
}
