import * as Colors from 'Constants/Colors'
import * as api from './api'

import { Progress, message } from 'antd'
import React, { useState } from 'react'

import { CsvRecord } from './type'
import Dropzone from 'react-dropzone'
import { InboxOutlined } from '@ant-design/icons'
import Parser from 'papaparse'
import styled from 'styled-components'

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`

const Instruction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed lightgray;
  padding: 20px 40px;
  margin: 20px;
  border-radius: 15px;
`

export const ImportMemberPage = (props) => {
  const [saving, setSaving] = useState(false)
  const [addedCount, setAddedCount] = useState(0)
  const [totalRecordCount, setTotalRecordCount] = useState(0)
  const percentage = (addedCount / totalRecordCount) * 100

  function onFilesDrop(files) {
    const firstFile = files[0]
    Parser.parse(firstFile, {
      header: true,
      complete: function (results) {
        const data = results.data as CsvRecord[]
        onGotData(data)
      },
    })
  }

  async function onGotData(records: CsvRecord[]) {
    setSaving(true)
    setTotalRecordCount(records.length)
    try {
      await api.importCsvRecords(records, function onProgress(addedCount) {
        setAddedCount(addedCount)
      })
      message.success('Import successful')
    } catch (e) {
      message.error('Error: ' + e.message)
    }
    // setSaving(false)
  }

  if (saving) {
    return (
      <Container>
        <div>Importing</div>
        <div>
          Added {addedCount}/{totalRecordCount}
        </div>
        <Progress percent={percentage}></Progress>
      </Container>
    )
  }

  return (
    <Container>
      <div>Import from file</div>
      <Dropzone accept=".csv" onDrop={onFilesDrop}>
        {({ getRootProps, getInputProps }) => (
          <Instruction {...getRootProps()}>
            <input {...getInputProps()} />
            <InboxOutlined
              style={{
                fontSize: '40px',
                color: Colors.PrimaryColor,
                marginBottom: '10px',
              }}
            />
            <p>Click or drag file to this area to import</p>
          </Instruction>
        )}
      </Dropzone>
    </Container>
  )
}
