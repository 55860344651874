import React from 'react'
import { Table } from 'antd'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

interface Props {
  members: any
  onMemberClick?: (member: any) => void
}

const Pointer = styled.div`
  cursor: pointer;
`

export const MemberList = observer((props: Props) => {
  const { members, onMemberClick } = props

  const columns = [
    {
      title: 'ឈ្មោះ',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return <Pointer onClick={() => onMemberClick(record)}>{text}</Pointer>
      },
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      render: (text, record) => {
        return <Pointer onClick={() => onMemberClick(record)}>{text}</Pointer>
      },
    },
    {
      title: 'Job',
      dataIndex: 'job',
      key: 'job',
      render: (text, record) => {
        return <Pointer onClick={() => onMemberClick(record)}>{text}</Pointer>
      },
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNo',
      key: 'phoneNo',
      render: (text, record) => {
        return <Pointer onClick={() => onMemberClick(record)}>{text}</Pointer>
      },
    },
  ]

  return <Table columns={columns} dataSource={members} />
})
