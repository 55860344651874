import * as Colors from 'Constants/Colors'

import { Button, Spin, message } from 'antd'
import React, { useEffect, useState } from 'react'

import { MemberList } from './MemberList'
import { houseStore } from 'Models/HouseModel'
import { memberStore } from 'Models/HouseMemberModel'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

const Container = styled.section`
  height: 100%;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  background: ${Colors.SecondaryColor};
  padding-left: 20px;
  height: 53px;
`

const Spinner = styled(Spin)`
  margin: 30px;
`

export const MemberListContainer = observer((props) => {
  const { onAddButtonClick } = props

  const { selectedHouse } = houseStore
  const { members, selectedMember = {} } = memberStore
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function loadMembers() {
      try {
        setLoading(true)
        await memberStore.loadMembers(selectedHouse.id)
        setLoading(false)
      } catch (e) {
        message.error('Cannot load members ' + e.message)
        setLoading(false)
      }
    }

    if (selectedHouse) {
      loadMembers()
    }
  }, [selectedHouse])

  function onMemberItemClick(member) {
    memberStore.setSelectedMember(member.id)
    props.onMemberItemClick && props.onMemberItemClick()
  }

  return (
    <Container>
      <Row>
        <h4>បញ្ជីសមាជិក</h4>
        {selectedHouse && (
          <Button onClick={onAddButtonClick} type="primary">
            បន្ថែម
          </Button>
        )}
      </Row>

      {loading ? (
        <Spinner size="large"></Spinner>
      ) : (
        <MemberList
          selectedMemberId={selectedMember.id}
          onMemberItemClick={onMemberItemClick}
          members={members}
        ></MemberList>
      )}
    </Container>
  )
})
