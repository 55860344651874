import { SERVER_URL } from 'Constants/Server'
import axios from 'axios'

export const deleteById = async (id: string) => {
  const url = `${SERVER_URL}/api/HouseMembers/${id}`
  const response = await axios.delete(url)
  return response.data
}

export const getMemberByHouseId = async (houseId: string) => {
  const filter = {
    where: {
      houseId: houseId,
    },
  }
  const url = `${SERVER_URL}/api/HouseMembers?filter=${JSON.stringify(filter)}`
  const response = await axios.get(url)
  return response.data
}

export const getMembersByFilter = async (filter: any) => {
  const url = `${SERVER_URL}/api/HouseMembers?filter=${JSON.stringify(filter)}`
  const result = await axios.get(url)
  return result.data
}

export const addMember = async (member: any) => {
  const url = `${SERVER_URL}/api/HouseMembers`
  const response = await axios.post(url, member)
  return response.data
}

export const editMember = async (values: any) => {
  const url = `${SERVER_URL}/api/HouseMembers/${values.id}`
  const data = values
  const response = await axios.patch(url, data)
  return response.data
}
