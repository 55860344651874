import * as api from './api'

import { applySnapshot, flow, getParent, types } from 'mobx-state-tree'

import moment from 'moment'
import { userStore } from 'Models/UserModel'

const Geometry = types.model('Geometry', {
  lat: types.maybeNull(types.number),
  lng: types.maybeNull(types.number),
})

const House = types
  .model('House', {
    id: types.identifier,
    houseNo: types.maybeNull(types.string),
    streetNo: types.maybeNull(types.string),
    village: types.maybeNull(types.string),
    commune: types.maybeNull(types.string),
    district: types.maybeNull(types.string),
    province: types.maybeNull(types.string),
    geometry: types.maybe(Geometry),
  })
  .actions((self) => ({
    delete: flow(function* () {
      yield api.deleteHouseById(self.id)
      getParent(self, 2).remove(self)
    }),
    edit: flow(function* (values) {
      const data = {
        id: self.id,
        ...values,
        editor: userStore.name,
      }
      yield api.editHouse(data)
      applySnapshot(self, data)
    }),
  }))

const HouseStore = types
  .model('HouseStore', {
    houses: types.optional(types.array(House), []),
    selectedHouse: types.maybe(types.safeReference(House)),
  })
  .actions((self) => ({
    addHouse: flow(function* (house) {
      const data = {
        ...house,
        editor: userStore.name,
        editorId: userStore.id,
      }
      const result = yield api.addHouse(data)
      self.houses.push(result)
      return result
    }),
    setSelectedHouse(house) {
      self.selectedHouse = house
    },
    loadHouses: flow(function* () {
      const result = yield api.getHouses(userStore.name)
      self.houses = result
    }),
    remove(item) {
      self.houses.splice(self.houses.indexOf(item), 1)
    },
  }))

export const houseStore = HouseStore.create({
  houses: [],
})
